//===========================================
// -Navbar start
//===========================================
.mmg-navbar{
    padding: 1rem 2.5rem;
    border-bottom: 2px solid #E0E0E0;
    .mmglogo{
        width: 100px;
        margin-right: 20px;
    }
    .client-logo{
        width: 50px;
    }
    small{
        font-size: 12px;
    }
    .left-logos{
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
}
//===========================================
// -Navbar end
//===========================================
