#g-recaptcha {
    display: inline-block;
}

.submitPin {
    width: 50%;
    height: 75px;
    border-radius: 3px;
    background: #f5f5f5;
    border: 1px solid #e9ebec;
    outline: none;
    -webkit-box-sizing: border-box !important;
    box-sizing: border-box !important;
    font-size: 30px;
    text-align: center;
    padding: 0 12px;
    color: #808080;
}