//===========================================
// -Forms Start
//===========================================
.showPassword {
	color: #424242;
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	text-transform: capitalize;
	input[type='checkbox'] {
		width: 20px;
		height: 16px;
		vertical-align: bottom;
	}
	span {
		margin-left: 10px;
	}
}
.forgotPassword{
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 16px;
	text-align: left;
	color: #258ADA;
	text-decoration: none;
	text-transform: capitalize;
}
.forms-label{
font-family: 'Roboto';
font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 16px;
text-transform: uppercase;
color: #424242;
margin: 32px 0 16px 0 ;
}
.forms-informational-text{
font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 19px;
text-align: center;
color: #424242;
}
.icon-calendar{
	position: relative;
	&::after{
		content: '';
		background: url('../../images/calendar.svg');
		position: absolute;
		right: 10px;
		top: 10px;
		width: 20px;
		height: 20px;
		background-repeat: no-repeat;
		background-size: cover;
	}
}
select{
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	padding-right: 20px;
    background-image: url('../../images/dropdown.svg') !important;
	background-size: 15px !important;
    background-repeat: no-repeat !important;
    background-position: 95% 50% !important;
}
input[type="radio"]{
	width: 15px;
	height: 15px;
}
//===========================================
// -Forms end
//===========================================
