//===========================================
// -Buttons start
//===========================================

.btn {
  cursor: pointer;
  &[disabled],
  &.disabled {
    cursor: not-allowed;
  }
}
//===========================================
// -Buttons end
//===========================================
   