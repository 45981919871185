.user-profile {
  .user-profile-image {
    /* Hide the image on screens smaller than 767px */
    @media (max-width: 991px) {
      display: none;
    }
  }
  .user-image {
    width: 160px;
    height: 160px;
  }

  .user-data {
    margin: 1.5rem 0;

    li {
      margin-bottom: 0.5rem;

      span {
        color: #424242;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        vertical-align: middle;
        margin-left: 0.5rem;
      }
    }
  }
}

.v-line {
  position: relative;
  padding-left: 2rem;
}

.v-line:before {
  position: absolute;
  width: 1px;
  height: 100%;
  background: #ccc;
  content: '';
  left: 0;
}

.profile-tabs {
  border: 0;

  li.nav-item {
    border: 0;
  }

  .nav-link {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    color: #AAA;
    border: 0;

    &:hover,
    &:focus {
      border: 0;
      border-color: transparent;
      outline: 0;
    }
  }

  .nav-link.active {
    border: 0;
    color: #424242;
    border-bottom: 2px solid #258ADA;
  }
}
