//===========================================
// -Helper Classes start
//===========================================

.rdp-day_selected{
	color: #000;
}
.rdp:not([dir='rtl']) .rdp-day_range_start:not(.rdp-day_range_end) {
	color: #fff;
	background-color: #258ADA;
}

.rdp:not([dir='rtl']) .rdp-day_range_end:not(.rdp-day_range_start) {
	color: #fff;
	background-color: #258ADA;
}

.rdp {
	--rdp-cell-size: 30px;
	--rdp-caption-font-size: 16px;
	--rdp-accent-color: #e7edff;
	--rdp-background-color: #e7edff;
	--rdp-accent-color-dark: #3003e1;
	--rdp-background-color-dark: #258ADA;
	--rdp-outline: 2px solid var(--rdp-accent-color);
	/* Outline border for focused elements */
	--rdp-outline-selected: 3px solid var(--rdp-accent-color);
	/* Outline border for focused _and_ selected elements */

	margin: 1em 0;
}
.rdp-head_cell{
	background: #EFF3FF;;
}

//===========================================
// -Helper Classes end
//===========================================
