.appointments {
  .appointment-card {
    border-radius: 4px;
    background: #fff;
    box-shadow: 0px 0px 8px 0px rgba(66, 66, 66, 0.12);
  }

  .date-info {
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    margin-top: 6px;
    text-transform: uppercase;
  }

  .labels-data {
    color: #AAA;
    font-family: Roboto;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
  }

  .labels-text {
    color: #424242;
    font-size: 0.75rem;
    font-weight: 400;
  }

}

.child-message {
  padding: 4rem;
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;

  p {
    font-size: 1rem;
    font-weight: 400;
  }
}

.main-content {
  padding: 3rem 4rem;
  position: relative;
  min-height: 560px;
}

.serives-card {
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0px 0px 8px -1px rgba(66, 66, 66, 0.12);
  padding: 1.5rem 1rem;
  min-height: 120px;
  max-height: 120px;
}

.sticky-buttons {
  width: 100%;
  background-color: transparent;
}

.back-button,
.next-button {
  padding: 10px 20px;
  border: none;
  background-color: #258ADA;
  color: #ffffff;
  cursor: pointer;
}

.back-button {
  background-color: transparent;
  color: #258ADA !important;
  text-decoration: none;
  border-color: #258ADA;
  border-radius: 40px;
  float: left;

  &:before,
  &:after {
    display: none !important;
  }

  /* You can change the colors as per your design */
}

.next-button {
  background-color: #258ADA;
  color: #fff !important;
  text-decoration: none;
  border-radius: 40px;
  float: right;

  &:before,
  &:after {
    display: none !important;
  }

  /* You can change the colors as per your design */
}

.dates {
  gap: 10px;
}

.Card {
  border-radius: 8px;
  background: #FFF;
  box-shadow: 0px 0px 8px 0px rgba(66, 66, 66, 0.12);
  padding: 20px;
}

.result-scroll-div {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 345px;
}

$charade: #282a37;
$bluebell: #258ADA;

.fancy-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5rem;

  div {
    position: absolute;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;

    &.ring {
      border-width: 0.5rem;
      border-style: solid;
      border-color: transparent;
      animation: 2s fancy infinite alternate;

      &:nth-child(1) {
        border-left-color: $bluebell;
        border-right-color: $bluebell;
      }

      &:nth-child(2) {
        border-top-color: $bluebell;
        border-bottom-color: $bluebell;
        animation-delay: 1s;
      }
    }

    &.dot {
      width: 1rem;
      height: 1rem;
      background: $bluebell;
    }
  }
}

@keyframes fancy {
  to {
    transform: rotate(360deg) scale(0.5);
  }
}

@media (max-width: 991px) {
  .appointment-steps-header-nav {
    display: none;
  }
}
